body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #282c34;
  background-image: url('assets/cards_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.App {
  width: 80%;
  height: 100vh;
  /* Высота экрана */
  margin: 0 auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

/*.controls {
  padding: 20px 0 0 20px;
}*/

.settingBtn {
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
}

.cards {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  display: flex;
  padding: 10px;
  flex: 0 0 auto;
  /* Фиксированная высота для верхнего списка */
}

.cardsArea {
  flex: 1 1 auto;
  border: 2px dashed #ccc;
}

.areaCardControls {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: absolute;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  bottom: 0;
  left: 0;
  padding: 5px;
  box-sizing: border-box;
  z-index: 5;
}

.areaCardControls .hiddenControl {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease, visibility 0.6s;
}

.areaCardControls .visibleControl {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.6s ease;
}

.controlIcon {
  cursor: pointer;
}

.fullCard {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;

  z-index: 99;
}

.fullCardControls {
  position: relative;
}

.fullCard .areaCardControls {
  top: 0;
  align-items: flex-start;
}

.fullCard img {
  border-radius: 10px;
  max-width: 95vw;
  max-height: 95vh;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 400px;
  background-color: #282c34;
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;
}

.modal-content ul {
  list-style-type: none;
}

.draggable-card {
  transform: rotate(0deg) !important;
}